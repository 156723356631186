
import { Development } from "pages/development";
import { Home } from "pages/home";
import { useRoutes } from "react-router-dom";

export const AppRoutes = () => {
  const commonRoutes = [
    { path: "/", element: <Home /> },
    // { path: "/", element: <Development /> },
];

  const element = useRoutes([...commonRoutes]);

  return <>{element}</>;
};
