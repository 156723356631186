import React from "react";
import VisionImg from "assets/images/vision.png";

export const OurMission = () => {
  return (
    <>
      <div id="mission" className="bg-white relative min-h-screen pb-8 pt-20">
        <div className="mx-8 md:mx-24 mb-4">
          <div className="text-2xl md:text-5xl uppercase mb-3">
            Our <span className="font-semibold">Mission</span>
          </div>
          <div className="mb-8 text-lg">
            At Ulinzi Elderly Care we strive to enhance the quality of life for
            elderly persons by providing compassionate care, support services,
            and advocacy, promoting dignity, independence, and social inclusion.
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="mx-8 md:ml-24">
            <div className="mb-4">
              <div className="text-2xl md:text-5xl uppercase mb-3">
                VISION <span className="font-semibold">STATEMENT</span>
              </div>
              <div className="mb-4 text-lg">
                A society where elderly persons are valued, respected, and
                empowered to live with dignity, purpose, and fulfillment
              </div>
            </div>
            <div className="border border-gray-100 rounded-3xl p-4 md:p-8 mt-12">
              <div className="">
                <div className="font-semibold text-lg mb-3">Compassion</div>
                <div className="mb-3">
                  Showing kindness, understanding, and respect for the dignity
                  and worth of each Elderly person
                </div>
                <hr className="mb-3" />
                <div className="font-semibold text-lg mb-3">Dignity</div>
                <div className="mb-3">
                  By honoring their autonomy, independence, privacy, and right
                  to make choices about their care and lifestyle.
                </div>
                <hr className="mb-3" />
                <div className="font-semibold text-lg mb-3">Empathy</div>
                <div className="mb-3">
                  Understanding and sharing the feelings, experiences, and
                  perspectives of elderly individuals
                </div>
                <hr className="mb-3" />
                <div className="font-semibold text-lg mb-3">Inclusivity</div>
                <div className="mb-3">
                  This entails creating an inclusive and welcoming environment
                  that respects the diversity, backgrounds, and experiences of
                  elderly individuals.
                </div>
                <hr className="mb-3" />
                <div className="font-semibold text-lg mb-3">Integrity</div>
                <div className="mb-3">
                  This is essential for building trust and credibility with
                  stakeholders, including elderly individuals, their families,
                  donors, and the community
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <img src={VisionImg} alt="Vision" />
          </div>
        </div>
      </div>
    </>
  );
};
