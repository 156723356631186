import React from "react";
import { BsFacebook, BsLinkedin, BsTwitter, BsYoutube } from "react-icons/bs";
import { Link } from "react-router-dom";
import { RiMapPin2Fill } from "react-icons/ri";
import { SlPhone } from "react-icons/sl";
import { HiMailOpen } from "react-icons/hi";

export const Footer = () => {
  return (
    <footer id="contact-us" className="py-8 mb-48 md:mb-0 md:py-16 text-xs md:text-sm px-24">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="flex flex-col items-center">
          <div className="font-semibold mb-4">Contact Us</div>
          <div className="font-light">
            <div className="flex flex-row items-center space-x-2">
              <SlPhone />
              <span>+256 782692271</span>
            </div>
            <div className="flex flex-row items-center space-x-2">
              <HiMailOpen />
              <span>info@ulinzi.ug</span>
            </div>
            <div className="flex flex-row items-center space-x-2">
              <RiMapPin2Fill />
              <span>
                Lorem ipsum dolor sit
                <br />
                Rhoncus amet vitae
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="font-semibold mb-4">Useful Links</div>
          <div className="font-light">
            <Link
              to="/fundraising-plan"
              className="flex flex-row items-center space-x-2"
            >
              Fundraising Plan
            </Link>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="font-semibold mb-4">Follow Us</div>
          <div className="flex flex-row items-center space-x-2">
            <BsFacebook size={24} />
            <BsTwitter size={24} />
            <BsLinkedin size={24} />
            <BsYoutube size={24} />
          </div>
        </div>
      </div>
      <div className="text-gray-900 text-center my-4 font-semibold ">
        © 2024. Ulinzi Limited. All rights reserved. Built by{" "}
        <a
          href="https://mwebaze.com"
          target="_blank"
          className="text-green-500"
        >
          Jonahgeek
        </a>
      </div>
    </footer>
  );
};
