import React, { useState, useEffect } from "react";
import LogoImg from "assets/images/logo.png";
import { Link } from "react-scroll";
import { SlClose, SlMenu } from "react-icons/sl";

export const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`${
        isScrolled ? "bg-gray-900 text-white" : "bg-white text-gray-800"
      } fixed w-full z-20 transition-all duration-300`}
    >
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-3 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link
              to="home"
              spy={true}
              smooth={true}
              duration={500}
              onClick={() => setOpen(false)}
              className="cursor-pointer"
            >
              <img src={LogoImg} alt="Logo" className="h-12 sm:h-16" />
            </Link>
          </div>
          <div className="flex items-center">
            <div className="-mr-2 -my-2 md:hidden relative z-30">
              <button
                onClick={() => setOpen(!open)}
                className="bg-transparent rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              >
                {open ? (
                  <SlClose className="h-6 w-6" />
                ) : (
                  <SlMenu className="h-6 w-6" />
                )}
              </button>
            </div>
            <nav
              className={`${
                open
                  ? "absolute top-0 left-0 w-full h-screen flex flex-col items-center justify-center bg-gray-900 space-y-4 z-20"
                  : "hidden"
              } md:flex md:flex-row md:space-x-10`}
            >
              <Link
                to="home"
                spy={true}
                smooth={true}
                offset={-200}
                duration={500}
                onClick={() => setOpen(false)}
                className="text-base font-medium cursor-pointer text-white md:text-gray-800"
              >
                Home
              </Link>
              <Link
                to="about-us"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => setOpen(false)}
                className="text-base font-medium cursor-pointer text-white md:text-gray-800"
              >
                About Us
              </Link>
              <Link
                to="services"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => setOpen(false)}
                className="text-base font-medium cursor-pointer text-white md:text-gray-800"
              >
                Services
              </Link>
              <Link
                to="contact-us"
                spy={true}
                smooth={true}
                offset={-200}
                duration={500}
                onClick={() => setOpen(false)}
                className="text-base font-medium cursor-pointer text-white md:text-gray-800"
              >
                Contact Us
              </Link>
              <Link
                to="fundraising-plan"
                spy={true}
                smooth={true}
                offset={-200}
                duration={500}
                onClick={() => setOpen(false)}
                className="text-base font-medium cursor-pointer text-green-600"
              >
                Fundraising Plan
              </Link>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
