import { MainLayout } from "components";
import React from "react";
import AboutUsOne from "assets/images/about-us-1.png";
import AboutUsTwo from "assets/images/about-us-2.png";
import AboutUsThree from "assets/images/about-us-3.png";
import AboutUsFour from "assets/images/about-us-4.png";
import { BsChevronRight } from "react-icons/bs";

export const AboutUs = () => {
  return (
    <>
      <div id="about-us" className="bg-white relative h-screen pt-20 px-24 pb-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="">
          <div className="text-3xl md:text-5xl uppercase mb-3">About <span className="font-semibold">Us</span></div>
            <div className="mb-4 text-lg">
              Ulinzi Elderly Care Initiative (UECI) Limited (Limited by
              Guarantee) was established in 2024 in response to the growing
              needs of elderly persons in our community.
            </div>
            <div className="mb-4">
              With an aging population and limited support systems, many elderly
              persons face challenges such as social isolation, limited access
              to healthcare, and financial insecurity. UECI was founded to
              address these challenges and provide holistic support services to
              elderly persons and their families.
            </div>
            <button className="bg-green-500 p-3 rounded-lg px-12 text-white flex flex-row items-center space-x-2">
              <span>DONATE NOW</span>
              <BsChevronRight />
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 justify-center gap-4">
            <div className="">
                <img src={AboutUsOne} alt="About us" />
            </div>
            <div className="hidden md:block">
                <img src={AboutUsTwo} alt="About us" />
            </div>
            <div className="hidden md:block">
                <img src={AboutUsThree} alt="About us" />
            </div>
            <div className="hidden md:block">
                <img src={AboutUsFour} alt="About us" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
