import { MainLayout } from "components";
import React from "react";
import BannerImage from "assets/images/banner.png";
import { BsChevronRight } from "react-icons/bs";
import { AboutUs } from "../components/AboutUs";
import { Services } from "../components/Services";
import { OurMission } from "../components/OurMission";

export const Home = () => {
  return (
    <MainLayout>
      <div id="home" className="bg-gray-100 relative h-screen pt-32 md:pt-48 px-8 md:px-24">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="mb-3 text-center md:text-start">
            <div className="text-2xl md:text-6xl mb-3">
              Empowering Elders, Enriching Lives for a Better Tomorrow
            </div>
            <div className="mb-4">
              Aiming to deliver comprehensive support services, enhancing the
              well-being of elderly persons and their families.
            </div>
            <button className="mx-auto md:mx-0 bg-green-500 p-3 rounded-lg px-12 text-white flex flex-row items-center space-x-2">
              <span>REACH OUT</span>
              <BsChevronRight />
            </button>
          </div>
        <div className="mt-8 md:mt-0 flex items-center md:items-end justify-center md:justify-end">
          <img src={BannerImage} alt="Banner" className="md:w-3/4" />
        </div>
        </div>
      </div>
      <AboutUs />
      <Services />
      <OurMission />
    </MainLayout>
  );
};
