import { MainLayout } from "components";
import React from "react";
import CareAndSupportImg from "assets/images/care-and-support.svg";
import HealthCareImg from "assets/images/health-care.svg";
import OutreachAndAwarenessImg from "assets/images/outreach-and-awareness.svg";
import CommunityEngagementImg from "assets/images/community-engagement.svg";
import HumanRightsProtectionImg from "assets/images/human-rights.svg";

export const Services = () => {
  return (
    <>
      <div
        id="services"
        className="bg-gray-100 relative min-h-screen pt-20 px-8 md:px-24 pb-8"
      >
        <div className="text-3xl md:text-5xl text-center uppercase mb-3">
          Our <span className="font-semibold">Services</span>
        </div>
        <div className="mb-4 text-lg text-center mb-8">
          At Ulinzi Elderly Care Initiative (UECI) Limited, we offer a wide
          range of programs and services designed to meet the diverse needs of
          our elderly community. Our comprehensive care approach includes
          personalized in-home assistance, health care services, outreach and
          awareness activities, community engagement initiatives, and human
          rights protection.
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <div className="bg-white border border-gray-200 rounded-2xl flex flex-col items-center space-y-4 p-8">
            <div className=" h-32 w-32">
              <img src={CareAndSupportImg} alt="Care and Support" />
            </div>
            <div className="font-semibold text-lg">Care and Support</div>
            <div className="text-center">
              Providing personalized and compassionate care to help elderly
              individuals live independently and comfortably.{" "}
            </div>
          </div>
          <div className="bg-white border border-gray-200 rounded-2xl flex flex-col items-center space-y-4 p-8">
            <div className=" h-32 w-32">
              <img src={HealthCareImg} alt="Health Care" />
            </div>
            <div className="font-semibold text-lg">Health Care</div>
            <div className="text-center">
              Providing personalized and compassionate care to help elderly
              individuals live independently and comfortably.
            </div>
          </div>
          <div className="bg-white border border-gray-200 rounded-2xl flex flex-col items-center space-y-4 p-8">
            <div className=" h-32 w-32">
              <img src={OutreachAndAwarenessImg} alt="Outreach and Awareness" />
            </div>
            <div className="font-semibold text-lg">Outreach and Awareness</div>
            <div className="text-center">
              Providing personalized and compassionate care to help elderly
              individuals live independently and comfortably.
            </div>
          </div>
        </div>
        <div className="md:mx-24 grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div className="bg-white border border-gray-200 rounded-2xl flex flex-col items-center space-y-4 p-8">
            <div className=" h-32 w-32">
              <img src={CommunityEngagementImg} alt="Community Engagement" />
            </div>
            <div className="font-semibold text-lg">Community Engagement</div>
            <div className="text-center">
              Providing personalized and compassionate care to help elderly
              individuals live independently and comfortably.
            </div>
          </div>
          <div className="bg-white border border-gray-200 rounded-2xl flex flex-col items-center space-y-4 p-8">
            <div className=" h-32 w-32">
              <img src={HumanRightsProtectionImg} alt="Human Rights Protection" />
              </div>
            <div className="font-semibold text-lg">Human Rights Protection</div>
            <div className="text-center">
              Providing personalized and compassionate care to help elderly
              individuals live independently and comfortably.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
